import React from "react";
import { diffBlock } from "./DiffArea";
import { Diff } from "diff-match-patch";
import LZString from "lz-string";

interface propInterface {
  block: diffBlock;
}

interface rowPropInterface {
  index: number;
  diffs: Diff[];
  selected: boolean;
  onClick: Function;
}

interface rowInterface {
  diffs: Diff[];
  selected: boolean;
}

interface stateInterface {
  rows: rowInterface[];
}

class Row extends React.Component<rowPropInterface, any> {
  render() {
    var tmpRow = this.props.diffs.map((diff) => {
      return (
        <span
          className={"diff-" + diff[0]}
          dangerouslySetInnerHTML={{ __html: diff[1] }} // to more properly render whitespace, it's a bug in jsx
        ></span>
      );
    });

    return (
      <div
        className={"row " + (this.props.selected ? "" : "unselected")}
        onClick={() => {
          this.props.onClick();
        }}
      >
        <b>{this.props.index}.</b> {tmpRow}
      </div>
    );
  }
}

export default class DiffField extends React.Component<
  propInterface,
  stateInterface
> {
  constructor(props: propInterface) {
    super(props);

    this.state = {
      rows: this.getRow(this.props.block.diffs),
    };

    this.setState({
      rows: this.getRow(this.props.block.diffs),
    });
    this.exportReport = this.exportReport.bind(this);
  }

  componentDidUpdate(prevProps: propInterface) {
    if (prevProps.block !== this.props.block) {
      this.setState({
        rows: this.getRow(this.props.block.diffs),
      });
    }
  }

  getRow(diffs: Diff[]): rowInterface[] {
    // instead of render block on the fly we create rows out of block
    var outRows: rowInterface[] = [];
    var currentRow: Diff[] = [];

    diffs.forEach((diff) => {
      const sanitizedText = diff[1]
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/&/g, "&amp;")
        .replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;");
      const rows = sanitizedText.split(/\n/g);

      rows.forEach((text, i) => {
        currentRow.push([diff[0], text]);
        // if we find a line break we push the line to rows
        if (rows.length != i + 1) {
          outRows.push({ diffs: currentRow, selected: true });
          currentRow = Array();
        }
      });
    });
    outRows.push({ diffs: currentRow, selected: true });

    return outRows;
  }

  exportReport() {
    const stringToCompress = LZString.compressToEncodedURIComponent(
      JSON.stringify(this.state.rows)
    );
    const compressed = encodeURIComponent(stringToCompress);
    window.open("/exportfile.html?diffmap=" + compressed);
  }

  render() {
    var diffMap = this.state.rows.map((row, i) => {
      return (
        <Row
          index={i + 1}
          diffs={row.diffs}
          selected={row.selected}
          onClick={() => {
            row.selected = !row.selected;
            var tmpRows = this.state.rows;
            tmpRows[i] = row;
            this.setState({
              rows: tmpRows,
            });
          }}
        />
      );
    });

    return (
      <div className="DiffField">
        <div className="ButtonArea">
          <button
            onClick={() => {
              const rows = this.state.rows.map((row) => {
                row.selected = false;
                return row;
              });

              this.setState({
                rows: rows,
              });
            }}
          >
            deselect all
          </button>

          <button
            onClick={() => {
              const rows = this.state.rows.map((row) => {
                row.selected = true;
                return row;
              });

              this.setState({
                rows: rows,
              });
            }}
          >
            select all
          </button>

          <button onClick={this.exportReport}>Export diff report</button>
        </div>
        {diffMap}
      </div>
    );
  }
}
