import React from "react";
import logo from "./logo.svg";
import DiffArea from "./DiffArea";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>WT-DIFF</h1>
      </header>
      <DiffArea />

      <footer>Wiretronic AB - 2021</footer>
    </div>
  );
}

export default App;
