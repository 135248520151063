import React, { Component } from "react";
import DiffField from "./DiffField";
import DiffMatchPatch, { Diff, diff_match_patch } from "diff-match-patch";

export interface diffBlock {
  text: string;
  diffs: Diff[];
}

interface stateInterface {
  text1: string;
  text2: string;
  block: diffBlock;
}

export default class DiffArea extends Component<any, stateInterface> {
  constructor(props: any) {
    super(props);

    this.state = {
      text1: "Add text to compare here",
      text2: "Add text to compare here",
      block: { text: "", diffs: [] },
    };

    this.setDiffBlock = this.setDiffBlock.bind(this);
  }

  componentDidMount() {
    this.setDiffBlock();
  }

  setDiffBlock() {
    const dmp = new DiffMatchPatch.diff_match_patch();
    const diffs = dmp.diff_main(this.state.text1, this.state.text2);
    // here we compare the diffs of text1 and text2..
    this.setState({
      block: {
        text: "",
        diffs: diffs,
      },
    });
  }

  render() {
    return (
      <div>
        Simply copy and paste the two textfiles you want to compare here.
        <div className="InputArea">
          <textarea
            onChange={(e) => {
              this.setState({ text1: e.target.value }, this.setDiffBlock);
            }}
          >
            {this.state.text1}
          </textarea>
          <textarea
            onChange={(e) => {
              this.setState({ text2: e.target.value }, this.setDiffBlock);
            }}
          >
            {this.state.text2}
          </textarea>
        </div>
        <h3>Output:</h3>
        <DiffField block={this.state.block} />
      </div>
    );
  }
}
